import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Navigation = () => {
  const { settings } = useStaticQuery(query)

  return (
    <div className="border-gray-300 border-t">
      <div className="container">
        <nav className="items-center flex justify-around divide-x divide-gray-300 border-gray-300 border-l border-r">
          {settings.pages.map(page => (
            <Link
              key={page.slug.current}
              className="nav-item"
              partiallyActive={"/" !== page.slug.current && true}
              to={
                "/" === page.slug.current
                  ? page.slug.current
                  : "servicos" === page.slug.current
                  ? `/bd-service`
                  : `/${page.slug.current}`
              }
              activeClassName="is-active"
            >
              <span>{page.title}</span>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}

const query = graphql`
  {
    settings: sanitySettings {
      title
      pages {
        title
        slug {
          current
        }
      }
    }
  }
`

export default Navigation
