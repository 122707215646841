import React from "react"
import PropTypes from "prop-types"
import { Button, Link, Logo } from "components"
import { Navigation, TopBar, SearchBox } from "./components"

const Header = ({ title }) => {
  return (
    <header className="bg-white shadow-lg relative z-40">
      <TopBar />

      <div className="flex container w-full justify-between items-center py-8">
        <div className="w-48">
          <Link className="text-left justify-start flex w-48" to="/">
            <Logo />
          </Link>
        </div>

        <SearchBox />

        <Button isPrimary to="/contato">
          Orçamentos
        </Button>
      </div>

      <Navigation />
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
